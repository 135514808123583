import React, {useState} from "react";
import {enqueueSnackbar} from "notistack";
import {Button, Sheet} from "@mui/joy";
import {ModalComponent} from "../../popups/ModalComponent";
import {Input, SelectInputOption} from "../../components/Input";
import {CashierBalance} from "../../models/cashierBalance";
import {Nullable} from "../../common/commons";
import {useCreateCashTransfer} from "../../api/cashier/use-create-cash-transfer";

export const CashTransferModal = ({balances, onClosed}: { balances: CashierBalance[], onClosed: () => void }) => {
    const [selectedOriginUserId, setSelectedOriginUserId] = useState<string>(null)
    const [selectedTargetUserId, setSelectedTargetUserId] = useState<string>(null)
    const [depositAmount, setDepositAmount] = useState<number>(null)

    const {mutate: createBankDeposit, isPending: creatingBankDeposit} = useCreateCashTransfer()

    const selectedOriginUser = balances.find(b => b.id == selectedOriginUserId)
    const selectedTargetUser = balances.find(b => b.id == selectedTargetUserId)

    const originUsers = balances
        .filter(u => u.used_balance > 0)
        .map(u => ({
            val: u.id,
            name: `${u.name} (יתרה: ${u.used_balance.toFixed(2)} ₪)`
        } as SelectInputOption))

    const targetUsers = balances
        .filter(u => u.available_balance > 0 && u.id != selectedOriginUserId)
        .map(u => ({
            val: u.id,
            name: `${u.name} (יתרה פנויה: ${u.available_balance.toFixed(2)} ₪)`
        } as SelectInputOption))

    const handleCreateDeposit = () => {
        if (!selectedOriginUser || !selectedTargetUser) {
            return
        }

        createBankDeposit({
            origin_user_id: selectedOriginUserId,
            target_user_id: selectedTargetUserId,
            amount: depositAmount
        }, {
            onSuccess: () => {
                enqueueSnackbar("העברה נוצרה בהצלחה", {variant: "success", autoHideDuration: 3000})
                onClosed()
            },
            onError: (error) => {
                enqueueSnackbar(`שגיאה ביצירת העברה: ${(error as any).response?.data?.message}`, {
                    variant: "error",
                    autoHideDuration: 5000
                })
            }
        })
    }

    function handleUserSelectionChanged(userId: Nullable<string>) {
        setSelectedOriginUserId(userId)
        setDepositAmount(balances.find(b => b.id == userId)?.used_balance ?? null)
    }

    function getAmountError(): Nullable<string> {
        if (depositAmount <= 0) {
            return "סכום ההעברה צריך להיון יותר מ 0"
        }

        if (depositAmount > selectedOriginUser.used_balance) {
            return "סכום ההפקדה גבוה מיתרת משתמש"
        }

        if (depositAmount > selectedTargetUser.available_balance) {
            return "סכום ההפקדה גבוה מיתרה פנויה של היעד"
        }
    }

    return (
        <ModalComponent title={"העברה בין משתמשים"} onClosed={onClosed}>
            <Sheet>
                <Input title={"בחר משתמש"}
                       type="select"
                       validate={true}
                       values={originUsers}
                       name="select-user"
                       value={selectedOriginUserId}
                       onChange={handleUserSelectionChanged}/>
                <br/>
                <Input title={"בחר משתמש יעד"}
                       type="select"
                       validate={true}
                       values={targetUsers}
                       name="select-user"
                       value={selectedTargetUserId}
                       onChange={setSelectedTargetUserId}/>
                <br/>
                {selectedOriginUser && selectedTargetUser && <>
                    <Input title={"סכום הפקדה"} validate={true} name="amount"
                           value={depositAmount}
                           type="number"
                           error={getAmountError()}
                           onChange={setDepositAmount}/>
                </>}
                <div dir={"ltr"} className={"flexWithGaps"} style={{marginTop: 10}}>
                    <Button
                        disabled={!selectedOriginUser || !selectedTargetUser || !depositAmount || getAmountError() != null || creatingBankDeposit}
                        onClick={() => handleCreateDeposit()}
                        loading={creatingBankDeposit}
                        loadingPosition="end"
                        className='btn small'>יצירה</Button>
                </div>
            </Sheet>
        </ModalComponent>
    )
}