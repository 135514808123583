import React, {useState} from 'react'
import {Link} from "react-router-dom";
import {IoCheckmarkDoneSharp} from "react-icons/io5";
import {exportToExcel} from "../../common/exportExcel";
import Skeleton from "@mui/joy/Skeleton";
import {Button} from "@mui/joy";
import {useListCashierBalances} from "../../api/cashier/use-list-cashier-balances";
import {useUIContext} from "../../contexts/ui.context";
import Box from "@mui/joy/Box";
import {CashTransferModal} from "./CashTransferModal";
import {BankDepositModal} from "./BankDepositModal";
import {TableComponent} from "../../components/Table/TableComponent";

export default function CashiersPage() {
    const [showBankDepositModal, setShowBankDepositModal] = useState<boolean>(false)
    const [showCashTransferModal, setShowCashTransferModal] = useState<boolean>(false)
    const {data: balances, isLoading} = useListCashierBalances()
    const {userPermissions} = useUIContext()

    const handleExportClick = () => {
        const exportData = balances.map(balance => ({
            'שם': balance.name,
            'יתרת קופה': balance.used_balance,
            'מגבלה': balance.limit,
        }));

        exportToExcel(exportData, "יתרות משתמשים")
    };

    return (<>
            {showBankDepositModal &&
                <BankDepositModal balances={balances} onClosed={() => setShowBankDepositModal(false)}/>}
            {showCashTransferModal &&
                <CashTransferModal balances={balances} onClosed={() => setShowCashTransferModal(false)}/>}
        <div className='body'>
            <div className='bodyPathInfo'>
                <div className='data'>
                    <div className='dataHead'>
                        <div dir="rtl">
                            {userPermissions?.CASHIER?.create && <Box className={"flexWithGaps"}>
                                <Button onClick={() => setShowCashTransferModal(true)} className='btn small'>העברת יתרה
                                    בין
                                    משתמשים</Button>
                                <Button onClick={() => setShowBankDepositModal(true)} className='btn small'>הפקדה
                                    לבנק</Button>
                            </Box>}
                        </div>
                        <div className='buttonsData'>
                            <Button onClick={handleExportClick} className='btn small'>
                                <IoCheckmarkDoneSharp/> יצוא ל
                                EXCEL
                            </Button>
                        </div>

                    </div>

                    <div id='sec1' className='inData checked'>
                        <TableComponent>
                            <thead>
                            <tr>
                                <td>#</td>
                                <td className='text-right' style={{width: "25%"}}>משתמש</td>
                                <td>מגבלה</td>
                                <td>יתרת קופה</td>
                            </tr>
                            </thead>
                            <tbody>
                            {isLoading ?
                                [...Array(12)].map((_, index) =>
                                    <tr key={index}>
                                        <td colSpan={12}><Skeleton variant="text" width={'100%'} height={30}/></td>
                                    </tr>) : balances?.map((balanceItem, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td className='text-right'><Link
                                            to={`/users/${balanceItem.id}`}>{balanceItem.name}</Link>
                                        </td>
                                        <td>{balanceItem.limit?.toFixed(2)} ₪</td>
                                        <td>{balanceItem.used_balance.toFixed(2)} ₪</td>
                                    </tr>
                                )}
                            </tbody>

                            {balances?.length && <tfoot>
                            <tr>
                                <td style={{textAlign: 'left'}} colSpan={3} scope="row"><b>סה"כ</b></td>
                                <td style={{textAlign: 'center'}} colSpan={1} scope="row">
                                    <b style={{fontWeight: "bold", color: "#000", fontSize: 15}}>
                                        {balances.reduce((acc, b) => acc + b.used_balance, 0).toFixed(2)} ₪
                                    </b>
                                </td>
                            </tr>
                            </tfoot>}
                        </TableComponent>

                        {!isLoading && !balances?.length &&
                            <div className='tableNoData'>לא נמצאו נתונים במחלקה זו.</div>}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

