import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, {listItemButtonClasses} from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {closeSidebar} from './utils';
import {useUIContext} from "../../contexts/ui.context";
import {useLogoutMutation} from "../../api/users/use-logout";
import {appRoutes, isRouteAccessibleWithProviderPermissions} from "./routes";
import {Link, useLocation} from "react-router-dom";
import {useFetchCashierMyBalance} from "../../api/cashier/use-get-my-cashier-balance";
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';

function Toggler({
                     groupPath,
                     renderToggle,
                     children,
                 }: {
    groupPath: string;
    renderToggle: (isOpen: boolean) => React.ReactNode;
    children: React.ReactNode;
}) {
    const {pathname} = useLocation();
    const mainPath = pathname?.split("/")[1];
    const isOpen = () => groupPath.split("/")[1] == mainPath
    return (
        <React.Fragment>
            {renderToggle(isOpen())}
            <Box
                sx={[
                    {
                        display: 'grid',
                        transition: '0.2s ease',
                        '& > *': {
                            overflow: 'hidden',
                        },
                    },
                    isOpen() ? {gridTemplateRows: '1fr'} : {gridTemplateRows: '0fr'},
                ]}
            >
                {children}
            </Box>
        </React.Fragment>
    );
}

export default function Sidebar() {
    const {userinfo: [loggedInUser, setLoggedInUser]} = useUIContext();
    const {mutate: logout} = useLogoutMutation()
    const {userPermissions} = useUIContext();

    const {data: myCashierBalance} = useFetchCashierMyBalance()

    const {pathname} = useLocation();

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: {xs: 'fixed', md: 'sticky'},
                transform: {
                    xs: 'translateX(calc(-100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '220px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    right: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(-100% * (var(--SideNavigation-slideIn, 0) - 1) - var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center', placeContent: 'center'}}>
                <Link to="/">
                    <img alt="Rmobile Logo" height={65} src="/images/rmobile-logo-transparent.png"/>
                </Link>
            </Box>
            {/*<Input size="sm" startDecorator={<SearchRoundedIcon/>} placeholder="Search"/>*/}
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List size="sm" sx={{
                    gap: 1,
                    '--List-nestedInsetStart': '30px',
                    '--ListItem-radius': (theme) => theme.vars.radius.sm,
                }}>
                    {appRoutes
                        .filter(r => r.visibleOnMenu && isRouteAccessibleWithProviderPermissions(r, userPermissions))
                        .map(rootRoute => {
                            if (!rootRoute.children || !rootRoute.children.filter(r => r.visibleOnMenu).length) {
                                return (
                                    <ListItem>
                                        <ListItemButton
                                            selected={rootRoute.path == pathname}
                                            component={Link}
                                            role="menuitem"
                                            to={rootRoute.path}>
                                            {rootRoute.icon}
                                            <ListItemContent>
                                                <Typography level="title-sm">{rootRoute.label}</Typography>
                                            </ListItemContent>
                                            {/*<Chip size="sm" color="primary" variant="solid">*/}
                                            {/*    4*/}
                                            {/*</Chip>*/}
                                        </ListItemButton>
                                    </ListItem>
                                );
                            }

                            return (
                                <ListItem nested>
                                    <Toggler
                                        groupPath={rootRoute.path}
                                        renderToggle={(isOpen) => (
                                            <ListItemButton
                                                component={Link}
                                                to={rootRoute.path}>
                                                {rootRoute.icon}
                                                <ListItemContent>
                                                    <Typography level="title-sm">{rootRoute.label}</Typography>
                                                </ListItemContent>
                                                <KeyboardArrowDownIcon
                                                    sx={[isOpen ? {transform: 'rotate(180deg)'} : {transform: 'none'}]}/>
                                            </ListItemButton>
                                        )}>
                                        <List sx={{gap: 0.5}}>
                                            {rootRoute.children
                                                .filter(r => r.visibleOnMenu && isRouteAccessibleWithProviderPermissions(r, userPermissions))
                                                .map(childRoute =>
                                                    <ListItem
                                                        sx={{mt: 0.5}}>
                                                        {childRoute.icon}
                                                        <ListItemButton
                                                            selected={childRoute.path == pathname}
                                                            component={Link}
                                                            to={childRoute.path}>{childRoute.label}</ListItemButton>
                                                    </ListItem>
                                                )}
                                        </List>
                                    </Toggler>
                                </ListItem>
                            )
                        })}
                </List>
            </Box>
            <Divider/>
            <Box style={{display: 'flex', columnGap: 15, paddingRight: 5, alignItems: 'center'}}>
                <PaymentsTwoToneIcon/>
                <Box sx={{minWidth: 0, flex: 1}}>
                    <Typography color="primary" level="title-sm">יתרת קופה: {myCashierBalance?.used_balance.toFixed(2)} ₪</Typography>
                    <Typography color="danger"
                                level="title-sm">מגבלה: {myCashierBalance?.limit?.toFixed(2)} ₪</Typography>
                </Box>
            </Box>
            <Divider/>
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Avatar variant="outlined" size="sm" src={loggedInUser.avatar}/>
                <Box sx={{minWidth: 0, flex: 1}}>
                    <Typography level="title-sm">{loggedInUser?.name}</Typography>
                    <Typography level="body-xs">{loggedInUser?.email}</Typography>
                </Box>
                <IconButton size="sm" variant="plain" color="neutral">
                    <LogoutRoundedIcon onClick={() => logout()}/>
                </IconButton>
            </Box>
        </Sheet>
    );
}