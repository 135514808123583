import {API_ENDPOINTS} from "../endpoints";
import httpClient from "../httpClient";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Customer} from "../../models/Customer";

export interface CreateBankDepositPayload {
    user_id: number | string,
    amount: number,
    reference_id: string
}

const createBankDeposit = async (payload: CreateBankDepositPayload) => {
    const {data} = await httpClient.post(`${API_ENDPOINTS.CASHIER}/deposit`, payload);
    return data.data as Customer;
};

export const useCreateBankDeposit = () => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: createBankDeposit,
        onSuccess: async () => {
            await client.invalidateQueries({queryKey: [API_ENDPOINTS.CASHIER]})
        }
    });
};
