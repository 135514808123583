import {SubscriptionReportType} from "../../api/reports/use-get-subscriptions-reports";
import {PaymentReportType} from "../../api/reports/use-get-payments-reports";
import React, {ReactElement} from "react";
import {FaHome} from "react-icons/fa";
import {MdAttachMoney, MdManageAccounts, MdOutlineSettings} from "react-icons/md";
import {HiUsers} from "react-icons/hi";
import {UserPermissions} from "../../models/UserPermissions";
import {Nullable} from "../../common/commons";
import loadable from '@loadable/component'
import {GiTakeMyMoney} from "react-icons/gi";
import CashiersPage from "../cashier/CashiersPage";

const DashboardPage = loadable(() => import("../dashboard/DashboardPage"));
const CustomersPage = loadable(() => import("../customers/CustomersPage"));
const UsersPage = loadable(() => import("../users/UsersPage"));
const LogsPage = loadable(() => import("../logs/LogsPage"));
const EditCustomerPage = loadable(() => import("../customers/edit-customer"));
const SubscriptionReport = loadable(() => import("../reports/SubscriptionReport"));
const PackagesPage = loadable(() => import("../packages/PackagesPage"));
const Switchers = loadable(() => import("../settings/phone_settings"));
const EditPackagePage = loadable(() => import("../packages/EditPackagePage"));
const CreatePackagePage = loadable(() => import("../packages/CreatePackagePage"));
const CreateUserPage = loadable(() => import("../users/CreateUserPage"));
const EditUserPage = loadable(() => import("../users/EditUserPage"));
const UserRolesPage = loadable(() => import("../users/UserRolesPage"));
const CreateUserRolePage = loadable(() => import("../users/CreateUserRolePage"));
const EditUserRolePage = loadable(() => import("../users/EditUserRolePage"));
const PaymentsReport = loadable(() => import("../reports/PaymentsReport"));
const RecurringPaymentSubscriptionsReport = loadable(() => import("../reports/RecurringPaymentSubscriptionsReport"));
const CreateCustomerPage = loadable(() => import("../customers/create-customer"));
const CustomerPage = loadable(() => import("../customers/customerPage"));
const PaymentRedirectComponent = loadable(() => import("../../components/payment/PaymentRedirectComponent"));

export type NavigationItem = {
    visibleOnMenu: boolean;
    label: string;
    path: string;
    icon?: ReactElement;
    component?: JSX.Element;
    permissionGroup?: string;
    permissionLevel?: "view" | "edit" | "create" | "delete";
    children?: NavigationItem[];
}

export const appRoutes: NavigationItem[] = [
    {
        visibleOnMenu: true,
        label: 'דף הבית',
        path: '/',
        icon: <FaHome/>,
        component: <DashboardPage/>
    },
    {
        visibleOnMenu: true,
        label: 'לקוחות',
        path: '/customers',
        icon: <HiUsers/>,
        component: <CustomersPage/>,
        permissionGroup: "CUSTOMERS",
        permissionLevel: "view",
        children: [
            {
                visibleOnMenu: false,
                label: 'לקוחות',
                path: '/customers',
                permissionGroup: "CUSTOMERS",
                permissionLevel: "view",
                component: <CustomersPage/>
            },
            {
                visibleOnMenu: false,
                label: 'לקוח',
                path: '/customers/:id',
                permissionGroup: "CUSTOMERS",
                permissionLevel: "view",
                component: <CustomerPage/>
            },
            {
                visibleOnMenu: false,
                label: 'לקוח חדש',
                path: '/customers/new',
                permissionGroup: "CUSTOMERS",
                permissionLevel: "create",
                component: <CreateCustomerPage/>
            },
            {
                visibleOnMenu: false,
                label: 'עריכת פרטי לקוח',
                path: '/customers/edit/:id',
                permissionGroup: "CUSTOMERS",
                permissionLevel: "edit",
                component: <EditCustomerPage/>
            }
        ]
    },
    {
        visibleOnMenu: true,
        label: 'דוחות מנויים',
        path: '/subscriptions-reports/subscriptions',
        icon: <MdManageAccounts/>,
        permissionGroup: "REPORTS",
        permissionLevel: "view",
        children: [
            {
                visibleOnMenu: true,
                label: "מנויים",
                path: "/subscriptions-reports/subscriptions",
                permissionGroup: "REPORTS",
                permissionLevel: "view",
                component: <SubscriptionReport
                    type={SubscriptionReportType.ALL_SUBSCRIPTIONS} title={"מנויים"}
                    showDatesFilter={true}/>
            },
            {
                visibleOnMenu: true,
                label: "מנויים עם הוראת קבע",
                path: "/subscriptions-reports/subscriptions-with-recurring-subscriptions",
                permissionGroup: "REPORTS",
                permissionLevel: "view",
                component: <SubscriptionReport
                    type={SubscriptionReportType.CUSTOMERS_WITH_RECURRING_PLANS}
                    title={"מנויים עם הוראת קבע"} showDatesFilter={false}/>
            },
            {
                visibleOnMenu: true,
                label: "מנויים סלקום",
                path: "/subscriptions-reports/cellcom",
                permissionGroup: "REPORTS",
                permissionLevel: "view",
                component: <SubscriptionReport
                    type={SubscriptionReportType.CELLCOM} title={"מנויים סלקום"}
                    showDatesFilter={false}/>

            },
            {
                visibleOnMenu: true,
                label: "מנויים שאמורים להיות מנותקים",
                path: "/subscriptions-reports/subscriptions-should-be-disconnected",
                permissionGroup: "REPORTS",
                permissionLevel: "view",
                component: <SubscriptionReport
                    type={SubscriptionReportType.SHOULD_BE_DISCONNECTED}
                    title={"מנויים שאמורים להיות מנותקים"}
                    showDatesFilter={false}/>
            },
        ]
    },
    {
        visibleOnMenu: true,
        label: 'דוחות כספיים',
        path: '/payments-reports/receivables',
        icon: <GiTakeMyMoney/>,
        permissionGroup: "REPORTS",
        permissionLevel: "view",
        children: [
            {
                visibleOnMenu: true,
                label: "תקבולים",
                path: "/payments-reports/receivables",
                permissionGroup: "REPORTS",
                permissionLevel: "view",
                component: <PaymentsReport type={PaymentReportType.RECEIVABLES} showTypeFilter={true}
                                           title={"תקבולים"}/>
            },
            {
                visibleOnMenu: true,
                label: "העברות בהמתנה",
                path: "/payments-reports/pending-bank-transfers",
                permissionGroup: "REPORTS",
                permissionLevel: "view",
                component: <PaymentsReport type={PaymentReportType.PENDING_BANK_TRANSFERS} title={"העברות בהמתנה"}/>
            },
            {
                visibleOnMenu: true,
                label: "הוראות קבע",
                path: "/payments-reports/recurring-payment-subscriptions",
                permissionGroup: "REPORTS",
                permissionLevel: "view",
                component: <RecurringPaymentSubscriptionsReport/>
            }
        ]
    },
    {
        visibleOnMenu: true,
        label: 'היסטורית פעולות',
        path: '/logs',
        icon: <MdManageAccounts/>,
        permissionGroup: "REPORTS",
        permissionLevel: "view",
        component: <LogsPage/>
    },
    {
        visibleOnMenu: true,
        label: 'קופות משתמשים',
        path: '/cashiers-lis',
        icon: <MdAttachMoney/>,
        permissionGroup: "CASHIER",
        permissionLevel: "view",
        component: <CashiersPage/>
    },
    {
        visibleOnMenu: true,
        label: 'הגדרות מערכת',
        path: '/settings/packages',
        icon: <MdOutlineSettings/>,
        component: <PackagesPage/>,
        children: [
            {
                visibleOnMenu: true,
                label: 'חבילות',
                path: '/settings/packages',
                component: <PackagesPage/>
            },
            {
                visibleOnMenu: false,
                label: 'חבילה חדשה',
                path: '/settings/packages/new',
                component: <CreatePackagePage/>
            },
            {
                visibleOnMenu: false,
                label: 'עריכת חבילה',
                path: '/settings/packages/:id',
                component: <EditPackagePage/>
            },
            {
                visibleOnMenu: true,
                label: 'שלוחות',
                path: '/settings/switchers',
                component: <Switchers/>
            },
        ]
    },
    {
        visibleOnMenu: true,
        label: 'משתמשים',
        path: '/users',
        icon: <MdManageAccounts/>,
        component: <UsersPage/>,
        children: [
            {
                visibleOnMenu: true,
                label: 'משתמשים',
                path: '/users',
                component: <UsersPage/>,
            },
            {
                visibleOnMenu: false,
                label: 'משתמש חדש',
                path: '/users/new',
                component: <CreateUserPage/>,
            },
            {
                visibleOnMenu: false,
                label: 'עריכת משתמש',
                path: '/users/:id',
                component: <EditUserPage/>,
            },
            {
                visibleOnMenu: true,
                label: 'הרשאות',
                path: '/users/permissions',
                permissionGroup: "PERMISSIONS",
                permissionLevel: "view",
                component: <UserRolesPage/>,
            },
            {
                visibleOnMenu: false,
                label: 'קבוצת הרשאות חדשה',
                path: '/users/permissions/new',
                permissionGroup: "PERMISSIONS",
                permissionLevel: "create",
                component: <CreateUserRolePage/>,
            },
            {
                visibleOnMenu: false,
                label: 'עריכת קבוצת הרשאות',
                path: '/users/permissions/:id',
                permissionGroup: "PERMISSIONS",
                permissionLevel: "edit",
                component: <EditUserRolePage/>,
            }
        ]
    },
    {
        visibleOnMenu: false,
        label: 'payme hook',
        path: '/payment-hooks/payme',
        component: <PaymentRedirectComponent/>
    },

]

export const flattenAppRoutes = () => appRoutes.flatMap((group) => {
    if (!group.children || !group.children.length) {
        return group
    }

    if (!group.component) {
        return group.children
    }

    return [group, ...group.children]
})

export const getRouteByPath = (path) => flattenAppRoutes().find(s => s.path == path)

export const isRouteAccessibleWithProviderPermissions = (route: NavigationItem, permissions: Nullable<UserPermissions>) => {
    if (!route.permissionGroup || !route.permissionLevel) {
        return true;
    }

    if (!permissions) {
        return false
    }

    return permissions[route.permissionGroup][route.permissionLevel]
}