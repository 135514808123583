import httpClient from "../httpClient";
import {API_ENDPOINTS} from "../endpoints";
import {useUIContext} from "../../contexts/ui.context";
import {useMutation} from "@tanstack/react-query";

const logout = async () => {
    const {data} = await httpClient.post(API_ENDPOINTS.LOGOUT);
    return data
};

export const useLogoutMutation = () => {
    const {userinfo: [loggedInUser, setLoggedInUser]} = useUIContext();
    // const router = useRouter();

    return useMutation({
        mutationFn: logout,
        onSuccess: (_data) => {
            setLoggedInUser(null);
            window.location.reload()
            // router.push(`/${lang}`);
        },
        onError: (data) => {
            console.log(data, 'logout error response');
            setLoggedInUser(null);
            window.location.reload()
            // router.push(`/${lang}`);
        },
    });
};
