import React, {Dispatch, SetStateAction, useState} from 'react';
import {Nullable} from "../common/commons";
import {useGetUserProfile} from "../api/users/use-get-user-profile";
import {User} from "../models/user";
import {UserPermissions} from "../models/UserPermissions";
import {useGetUserPermissions} from "../api/users/user-get-user-permissions";

export interface UIContextState {
    userinfo: [Nullable<User>, Dispatch<SetStateAction<Nullable<User>>>];
    userPermissions: Nullable<UserPermissions>;
    success: [any, Dispatch<SetStateAction<any>>];
    menuState: [boolean, Dispatch<SetStateAction<boolean>>]
    loaderState: [boolean, Dispatch<SetStateAction<boolean>>]
}

const initialState = {
    userinfo: [],
    userPermissionsState: null,
    success: [],
    menuState: [],
    loaderState: []
}

export const UIContext = React.createContext<UIContextState | any>(initialState);
UIContext.displayName = 'UIContext';

export function UIProvider(props: React.PropsWithChildren) {
    const userinfo = useState<Nullable<User>>(null);
    const userPermissionsState = useState<Nullable<UserPermissions>>(null);
    const success = useState<any>(false);
    const menuState = useState<boolean>(false)
    const loaderState = useState<boolean>(true)

    const [loggedInUser, setLoggedInUser] = userinfo
    const [userPermissions, setUserPermissionsState] = userPermissionsState
    const [loading, setLoaderState] = loaderState


    const {data: user, isFetched: fetchedProfile} = useGetUserProfile()
    const {data: userPermissionsData, isFetched: fetchedPermissions} = useGetUserPermissions()

    if (!loggedInUser && user?.email) {
        setLoggedInUser(user)
    }

    if (!loggedInUser && user?.email) {
        setLoggedInUser(user)
    }
    if (!userPermissions && userPermissionsData) {
        setUserPermissionsState(userPermissionsData)
    }

    if (fetchedProfile && fetchedPermissions && loading) {
        setLoaderState(false)
    }

    const initialState = {
        userinfo,
        userPermissions,
        success,
        menuState,
        loaderState,
    }

    return <UIContext.Provider value={initialState} {...props} />;
}

export const useUIContext = () => {
    const context = React.useContext(UIContext);

    if (context === undefined) {
        throw new Error(`useUI must be used within a UIProvider`);
    }
    return context;
};

export function ManagedUIContext({children}: React.PropsWithChildren) {
    return (
        <UIProvider>
            {children}
        </UIProvider>
    );
}
