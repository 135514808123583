import httpClient from "../httpClient";
import {API_ENDPOINTS} from "../endpoints";
import {useQuery} from "@tanstack/react-query";
import {Nullable} from "../../common/commons";
import {Moment} from "moment";
import {Payment} from "../../models/payment";

export enum PaymentReportType {
    RECEIVABLES = "RECEIVABLES",
    PENDING_BANK_TRANSFERS = "PENDING_BANK_TRANSFERS"
}

const typeToPath = (type: PaymentReportType) => {
    switch (type) {
        case PaymentReportType.RECEIVABLES:
            return "receivables"
        case PaymentReportType.PENDING_BANK_TRANSFERS:
            return "pending-bank-transfer"
    }
}

const fetchPaymentsReport = async (type: PaymentReportType, from: Nullable<Moment>, toDate: Nullable<any>, paymentMethod: string) => {
    const url = `${API_ENDPOINTS.REPORTS}/payments/${typeToPath(type)}?from=${from?.format('YYYY-MM-DD') ?? ''}&to=${toDate?.format('YYYY-MM-DD') ?? ''}&payment_method=${paymentMethod}`
    const {data} = await httpClient.get(url);
    return data.data as Payment[];
};

export const useGetPaymentsReport = (type: PaymentReportType, from: Nullable<Moment>, toDate: Nullable<any>, paymentMethod: string) => {
    return useQuery<Payment[], Error>({
        queryKey: [API_ENDPOINTS.REPORTS, 'receivables', type, from, toDate, paymentMethod],
        queryFn: () => fetchPaymentsReport(type, from, toDate, paymentMethod),
    });
};