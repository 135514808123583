import {API_ENDPOINTS} from "../endpoints";
import httpClient from "../httpClient";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Customer} from "../../models/Customer";

export interface CreateBankDepositPayload {
    origin_user_id: number | string,
    target_user_id: number | string
    amount: number,
}

const createCashTransfer = async (payload: CreateBankDepositPayload) => {
    const {data} = await httpClient.post(`${API_ENDPOINTS.CASHIER}/cash-transfer`, payload);
    return data.data as Customer;
};

export const useCreateCashTransfer = () => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: createCashTransfer,
        onSuccess: async () => {
            await client.invalidateQueries({queryKey: [API_ENDPOINTS.CASHIER]})
        }
    });
};
