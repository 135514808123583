export const API_ENDPOINTS = {
    USERS: '/users',
    LOGIN: '/users/login',
    LOGOUT: '/users/logout',
    FORGET_PASSWORD: '/forget-password',
    DASHBOARD: '/dashboard',
    CUSTOMERS: '/customers',
    ORDERS: '/orders',
    SUBSCRIPTIONS: '/subscriptions',
    TASKS: '/tasks',
    PACKAGES: '/packages',
    INVOICES: '/invoices',
    PAYMENTS: '/payments',
    RECURRING_SUBSCRIPTIONS: '/recurring-subscriptions',
    REPORTS: '/reports',
    LOGS: '/logs',
    ROLES: '/roles',
    CASHIER: '/cashier'
};