import {useQuery} from '@tanstack/react-query';
import httpClient from "../httpClient";
import {API_ENDPOINTS} from "../endpoints";
import {User} from "../../models/user";


export const fetchUserProfile = async () => {
    const {data} = await httpClient.get(`${API_ENDPOINTS.USERS}/profile`);
    return data.data as User;
};
export const useGetUserProfile = () => {
    return useQuery<User, Error>({
        queryKey: [API_ENDPOINTS.USERS, 'profile'],
        queryFn: fetchUserProfile,
    });
};