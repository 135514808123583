import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import SnackBarNotification from "./components/SnackBarNotification";
import {useUIContext} from "./contexts/ui.context";
import {Loader} from "./components/Loader";

import './App.css';
import Layout from "./pages/common/layout";
import LoginPage from "./pages/login/LoginPage";
import {flattenAppRoutes, isRouteAccessibleWithProviderPermissions, NavigationItem} from "./pages/common/routes";
import AccessDeniedComponent from "./components/AccessDeniedComponent";

const PermissionsGuarderComponent = ({route, component}: {
    route: NavigationItem,
    component: JSX.Element
}): JSX.Element => {
    const {userPermissions} = useUIContext()
    const isAllowed = isRouteAccessibleWithProviderPermissions(route, userPermissions)

    if (!isAllowed) {
        return (<AccessDeniedComponent/>)
    }
    return (component)
}

export const RoutesDefinition = () => {
    return (<>
        <Routes>
            {flattenAppRoutes().map((route) =>
                <Route path={route.path}
                       element={<PermissionsGuarderComponent route={route} component={route.component}/>}/>)
            }
        </Routes>
    </>)
}

function App() {
    const {
        success: [success, setsuccess],
        userinfo: [logedIn, setlogedIn],
        loaderState: [loading, setLoaderState]
    } = useUIContext();

    return (
        <BrowserRouter>
            {loading && <Loader/>}
            {success && <SnackBarNotification data={success}/>}
            {logedIn?.id ?
                <Layout><RoutesDefinition/></Layout> :
                <Routes><Route path="*" element={<LoginPage/>}/></Routes>
            }
            </BrowserRouter>
    );
}
export default App;
