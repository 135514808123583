import axios from 'axios';

const httpClient = axios.create({
  baseURL: `//${window.location.host}/api/v1/`,
  timeout: 30_1000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Change request data/error here
httpClient.interceptors.request.use(
  (config) => {
    config.withCredentials = true
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default httpClient;
