import React, {CSSProperties} from 'react'

import './css/Form.css';
import {FormControl, FormHelperText, FormLabel, Input as JoyInput, Option, Select, Textarea} from '@mui/joy';
import {InfoOutlined} from "@mui/icons-material";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import FormatBold from "@mui/icons-material/FormatBold";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Check from "@mui/icons-material/Check";
import FormatItalic from "@mui/icons-material/FormatItalic";
import {Nullable} from "../common/commons";

export type SelectInputOption = {
    val: string | number| boolean;
    name: string;
    disabled?: boolean
}

export type InputComponentProps = {
    title?: string;
    name: string;
    validate?: boolean;
    type?: "text" | "number" | "password" | "email" | "textarea" | "select" | "datetime-local" | "date",
    placeholder?: string;
    style?: CSSProperties;
    error?: string;
    value?: string | number | boolean;
    values?: SelectInputOption[];
    onChange: (value: Nullable<any>) => any,
    inputStyle?: CSSProperties;
    disabled?: boolean
}

export const Input = (
    {
                          title,
                          name,
                          validate,
                          type = "text",
                          placeholder,
                          error,
                          value,
                          values,
                          onChange,
                          inputStyle,
                          disabled = false
    }: InputComponentProps) => {

    const handleSelectValueChange = (event, newValue) => {
        onChange(newValue)
    }

    const handleTextValueChange = (event) => {
        onChange(event.target.value)
    }

    if (type == "textarea") {
        const [italic, setItalic] = React.useState(false);
        const [fontWeight, setFontWeight] = React.useState('normal');
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

        return (<FormControl error={!!error}>
            {title && <FormLabel required={validate}>{title}</FormLabel>}
            <Textarea
                required={validate}
                error={!!error}
                style={inputStyle}
                disabled={disabled}
                minRows={3}
                name={name}
                onChange={handleTextValueChange}
                placeholder={placeholder}
                value={value as any}
                endDecorator={
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 'var(--Textarea-paddingBlock)',
                            pt: 'var(--Textarea-paddingBlock)',
                            borderTop: '1px solid',
                            borderColor: 'divider',
                            flex: 'auto',
                        }}
                    >
                        <IconButton
                            variant="plain"
                            color="neutral"
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                        >
                            <FormatBold/>
                            <KeyboardArrowDown fontSize="medium"/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            size="sm"
                            placement="bottom-start"
                            sx={{'--ListItemDecorator-size': '24px'}}
                        >
                            {['200', 'normal', 'bold'].map((weight) => (
                                <MenuItem
                                    key={weight}
                                    selected={fontWeight === weight}
                                    onClick={() => {
                                        setFontWeight(weight);
                                        setAnchorEl(null);
                                    }}
                                    sx={{fontWeight: weight}}
                                >
                                    <ListItemDecorator>
                                        {fontWeight === weight && <Check fontSize="small"/>}
                                    </ListItemDecorator>
                                    {weight === '200' ? 'lighter' : weight}
                                </MenuItem>
                            ))}
                        </Menu>
                        <IconButton
                            variant={italic ? 'soft' : 'plain'}
                            color={italic ? 'primary' : 'neutral'}
                            aria-pressed={italic}
                            onClick={() => setItalic((bool) => !bool)}
                        >
                            <FormatItalic/>
                        </IconButton>
                    </Box>
                }
                sx={[
                    {minWidth: 300, fontWeight},
                    italic ? {fontStyle: 'italic'} : {fontStyle: 'initial'},
                ]}
            />
            {error && <FormHelperText><InfoOutlined/>{error}</FormHelperText>}
        </FormControl>)
    }

    if (type == "select") {
        return (
            <FormControl error={!!error}>
                {title && <FormLabel required={validate}>{title}</FormLabel>}
                <Select
                    placeholder={title}
                    name={name}
                    defaultValue={value}
                    value={value as any}
                    disabled={disabled}
                    onChange={handleSelectValueChange}
                    required={validate}
                    sx={{minWidth: 200}}
                >
                    {values?.map((option) =>
                        <Option value={option.val}>{option.name}</Option>
                    )}
                </Select>
                {error && <FormHelperText><InfoOutlined/>{error}</FormHelperText>}
            </FormControl>
        )
    }

    return (<FormControl error={!!error}>
        {title && <FormLabel required={validate}>{title}</FormLabel>}
        <JoyInput autoComplete={name}
                  type={type}
                  style={inputStyle}
                  disabled={disabled}
                  required={validate}
                  error={!!error}
                  name={name}
                  value={value as any}
                  onChange={handleTextValueChange}
                  placeholder={placeholder}/>
        {error && <FormHelperText><InfoOutlined/>{error}</FormHelperText>}
    </FormControl>)
}
