import {Link} from "react-router-dom";

import {IoLockClosed} from "react-icons/io5";
import './css/Access.css';
import React from "react";
import {Button} from "@mui/joy";

export default function AccessDeniedComponent() {
    return(
        <div className='accessDenied'>
            <div>
                <IoLockClosed size={150}/>
                <h1>אין גישה לדף זה</h1>
                <p>שלום, אתה משתמש במערכת שמנסה להיכנס לדף שאין לך הרשאה להיכנס שליו, אנא חזור לדף הבית של המערכת ונסה שנית. או צור קשר עם מנהל המערכת.</p>
                <Link to="/" ><Button className='btn green'>חזור לדף הבית</Button></Link>
            </div>
        </div>
    );
}