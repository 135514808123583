import {useQuery} from '@tanstack/react-query';
import httpClient from "../httpClient";
import {API_ENDPOINTS} from "../endpoints";
import {CashierBalance} from "../../models/cashierBalance";


const getBalances = async () => {
    const {data} = await httpClient.get(API_ENDPOINTS.CASHIER);
    return data.data as CashierBalance[];
};
export const useListCashierBalances = () => {
    return useQuery<CashierBalance[], Error>({
        queryKey: [API_ENDPOINTS.CASHIER],
        queryFn: getBalances,
    });
};