import * as React from 'react';
import {CssVarsProvider, extendTheme, useColorScheme} from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton, {IconButtonProps} from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import {useLoginMutation} from "../../api/users/use-login";

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
    password: HTMLInputElement;
    persistent: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function ColorSchemeToggle(props: IconButtonProps) {
    const {onClick, ...other} = props;
    const {mode, setMode} = useColorScheme();
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => setMounted(true), []);

    return (
        <IconButton
            aria-label="toggle light/dark mode"
            size="sm"
            variant="outlined"
            disabled={!mounted}
            onClick={(event) => {
                setMode(mode === 'light' ? 'dark' : 'light');
                onClick?.(event);
            }}
            {...other}
        >
            {mode === 'light' ? <DarkModeRoundedIcon/> : <LightModeRoundedIcon/>}
        </IconButton>
    );
}

const customTheme = extendTheme({defaultColorScheme: 'dark'} as any);

export default function LoginPage() {

    const {mutate: login, isPending: loggingIn, error: loginError} = useLoginMutation()

    const handleLoginSubmit = (event: React.FormEvent<SignInFormElement>) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;

        login({email: formElements.email.value, password: formElements.password.value})
    }

    return (
        <CssVarsProvider theme={customTheme} disableTransitionOnChange>
            <CssBaseline/>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={(theme) => ({
                    width: {xs: '100%', md: '50vw'},
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{py: 3, display: 'flex', justifyContent: 'space-between'}}
                    >
                        <Box sx={{gap: 2, display: 'flex', alignItems: 'center'}}>
                            <img height={75} src={"/images/rmobile-logo-transparent.png"} alt={"Rmobile Logo"}/>
                        </Box>
                        {/*<ColorSchemeToggle/>*/}
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .MuiFormLabel-asterisk`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack sx={{gap: 4, mb: 2}}>
                            <Stack sx={{gap: 1}}>
                                <Typography component="h1" level="h3">
                                    כניסה למערכת
                                </Typography>
                                {/*<Typography level="body-sm">*/}
                                {/*    New to company?{' '}*/}
                                {/*    <Link href="#replace-with-a-link" level="title-sm">*/}
                                {/*        Sign up!*/}
                                {/*    </Link>*/}
                                {/*</Typography>*/}
                            </Stack>
                            {/*<Button*/}
                            {/*    variant="soft"*/}
                            {/*    color="neutral"*/}
                            {/*    fullWidth*/}
                            {/*    startDecorator={<GoogleIcon/>}*/}
                            {/*>*/}
                            {/*    Continue with Google*/}
                            {/*</Button>*/}
                        </Stack>
                        {/*<Divider*/}
                        {/*    sx={(theme) => ({*/}
                        {/*        [theme.getColorSchemeSelector('light')]: {*/}
                        {/*            color: {xs: '#FFF', md: 'text.tertiary'},*/}
                        {/*        },*/}
                        {/*    })}*/}
                        {/*>*/}
                        {/*    or*/}
                        {/*</Divider>*/}
                        <Stack sx={{gap: 4, mt: 2}}>
                            <form
                                onSubmit={handleLoginSubmit}
                            >
                                <FormControl required>
                                    <FormLabel>מייל</FormLabel>
                                    <Input type="email" name="email"/>
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>סיסמה</FormLabel>
                                    <Input type="password" name="password"/>
                                </FormControl>
                                <Stack sx={{gap: 4, mt: 2}}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/*<Checkbox size="sm" label="Remember me" name="persistent"/>*/}
                                        {/*<Link level="title-sm" href="#replace-with-a-link">*/}
                                        {/*    Forgot your password?*/}
                                        {/*</Link>*/}
                                        {(loginError as any)?.status == 422 && <span style={{
                                            color: "red",
                                            fontWeight: "bold"
                                        }}>שם משתמש ו/או סיסמה שגויים</span>}
                                    </Box>
                                    <Button loading={loggingIn} loadingPosition={"end"} type="submit" fullWidth>
                                        התחבר
                                    </Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Box>
                    <Box component="footer" sx={{py: 3}}>
                        <Typography level="body-xs" sx={{textAlign: 'center'}}>
                            © Rmobile {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    right: {xs: 0, md: '50vw'},
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    // backgroundImage:
                    //     'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
                    // [theme.getColorSchemeSelector('dark')]: {
                    //     backgroundImage:
                    //         'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
                    // },
                })}
            />
        </CssVarsProvider>
    );
}