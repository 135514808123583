import React, {useState} from "react";
import {DialogContent, DialogTitle, Modal, ModalClose, ModalDialog} from "@mui/joy";

export const ModalComponent = (
    {title, children, onClosed}:
        React.PropsWithChildren<{
            title: string
            onClosed: () => void
        }>
) => {
    const [showModal, setShowModal] = useState(true)

    const onModalClose = () => {
        setShowModal(false)
        onClosed()
    }

    return (<>
        <Modal open={showModal} onClose={onModalClose}>
            <ModalDialog>
                <ModalClose/>
                <DialogTitle style={{paddingRight: 25, marginTop: -9}}>{title}</DialogTitle>
                <DialogContent><div>{children}</div></DialogContent>
            </ModalDialog>
        </Modal>
    </>)
}