import {API_ENDPOINTS} from "../endpoints";
import httpClient from "../httpClient";
import {User} from "../../models/user";
import {useUIContext} from "../../contexts/ui.context";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";

export interface LoginInputType {
    email: string;
    password: string;
}

const fetchLogin = async (input: LoginInputType) => {
    const {data} = await httpClient.post(API_ENDPOINTS.LOGIN, input);
    return data.data as User;
};

export const useLoginMutation = () => {
    const {userinfo: [loggedInUser, setLoggedInUser], loaderState: [loading, setLoaderState]} = useUIContext();
    const navigate = useNavigate()
    const client = useQueryClient();

    return useMutation({

        mutationFn: (input: LoginInputType) => {
            setLoaderState(true)
            return fetchLogin(input)
        },
        onSuccess: async (user) => {
            setLoaderState(false)
            setLoggedInUser(user)
            await client.invalidateQueries({queryKey: [API_ENDPOINTS.USERS, "permissions"]})
            navigate("/")
        },
        onError: (error) => {
            setLoaderState(false)
            console.log(error, 'login error response');
        },
    });
};
