import React, {useState} from "react";
import {enqueueSnackbar} from "notistack";
import {Button, Sheet} from "@mui/joy";
import {ModalComponent} from "../../popups/ModalComponent";
import {Input, SelectInputOption} from "../../components/Input";
import {CashierBalance} from "../../models/cashierBalance";
import {Nullable} from "../../common/commons";
import {useCreateBankDeposit} from "../../api/cashier/use-create-bank-deposit";

export const BankDepositModal = ({balances, onClosed}: { balances: CashierBalance[], onClosed: () => void }) => {
    const [selectedUserId, setSelectedUserId] = useState<string>(null)
    const [depositAmount, setDepositAmount] = useState<number>(null)
    const [externalReferenceId, setExternalReferenceId] = useState<string>(null)

    const {mutate: createBankDeposit, isPending: creatingBankDeposit} = useCreateBankDeposit()

    const selectedUser = balances.find(b => b.id == selectedUserId)

    const UsersBalancesAsSelectOptions = balances
        .filter(u => u.used_balance > 0)
        .map(u => ({
            val: u.id,
            name: `${u.name} (${u.used_balance.toFixed(2)} ₪)`
        } as SelectInputOption))

    const handleCreateDeposit = () => {
        if (!selectedUserId) {
            return
        }

        createBankDeposit({user_id: selectedUserId, amount: depositAmount, reference_id: externalReferenceId}, {
            onSuccess: () => {
                enqueueSnackbar("הפקדה נוצרה בהצלחה", {variant: "success", autoHideDuration: 3000})
                onClosed()
            },
            onError: (error) => {
                enqueueSnackbar(`שגיאה ביצירת הפקדה: ${(error as any).response?.data?.message}`, {
                    variant: "error",
                    autoHideDuration: 5000
                })
            }
        })
    }

    function handleUserSelectionChanged(userId: Nullable<string>) {
        setSelectedUserId(userId)
        setDepositAmount(balances.find(b => b.id == userId)?.used_balance ?? null)
        setExternalReferenceId(null)
    }

    function getAmountError(): Nullable<string> {
        if (depositAmount <= 0) {
            return "סכום ההעברה צריך להיון יותר מ 0"
        }

        if (depositAmount > selectedUser.used_balance) {
            return "סכום ההפקדה גבוה מיתרת משתמש"
        }
    }

    return (
        <ModalComponent title={"הפקדה לבנק"} onClosed={onClosed}>
            <Sheet>
                <Input title={"בחר משתמש"}
                       type="select"
                       validate={true}
                       values={UsersBalancesAsSelectOptions}
                       name="select-user"
                       value={selectedUserId}
                       onChange={handleUserSelectionChanged}/>
                <br/>
                {selectedUserId && <>
                    <Input title={"סכום הפקדה"} validate={true} name="amount"
                           value={depositAmount}
                           type="number"
                           error={getAmountError()}
                           onChange={setDepositAmount}/>
                    <Input title="מספר אסמכתא" name="external-reference-id" value={externalReferenceId}
                           onChange={setExternalReferenceId}/>
                </>}
                <div dir={"ltr"} className={"flexWithGaps"} style={{marginTop: 10}}>
                    <Button
                        disabled={!selectedUserId || !depositAmount || getAmountError() != null || creatingBankDeposit}
                        onClick={() => handleCreateDeposit()}
                        loading={creatingBankDeposit}
                        loadingPosition="end"
                        className='btn small'>יצירה</Button>
                </div>
            </Sheet>
        </ModalComponent>
    )
}