import httpClient from "../httpClient";
import {API_ENDPOINTS} from "../endpoints";
import {useQuery} from "@tanstack/react-query";
import {Subscription} from "../../models/subscription";
import {Nullable} from "../../common/commons";
import {Moment} from "moment";

export enum SubscriptionReportType {
    ALL_SUBSCRIPTIONS="ALL_SUBSCRIPTIONS",
    CUSTOMERS_WITH_RECURRING_PLANS="CUSTOMERS_WITH_RECURRING_PLANS",
    SHOULD_BE_DISCONNECTED = "SHOULD_BE_DISCONNECTED",
    CELLCOM = "CELLCOM",
}

const typeToPath = (type: SubscriptionReportType) => {
    switch (type) {
        case SubscriptionReportType.CUSTOMERS_WITH_RECURRING_PLANS:
            return "recurring"
        case SubscriptionReportType.SHOULD_BE_DISCONNECTED:
            return "should-be-disconnected"
        case SubscriptionReportType.CELLCOM:
            return "cellcom"
        case SubscriptionReportType.ALL_SUBSCRIPTIONS:
            return "all-reports"
    }
}

const fetchSubscriptionsReport = async (type: SubscriptionReportType, from: Nullable<Moment>, toDate: Nullable<any>) => {
    const url = `${API_ENDPOINTS.REPORTS}/subscriptions/${typeToPath(type)}?from=${from?.format('YYYY-MM-DD') ?? ''}&to=${toDate?.format('YYYY-MM-DD') ?? ''}`
    const {data} = await httpClient.get(url);
    return data.data as Subscription[];
};

export const useGetSubscriptionsReport = (type: SubscriptionReportType, from: Nullable<Moment>, toDate: Nullable<any>) => {
    return useQuery<Subscription[], Error>({
        queryKey: [API_ENDPOINTS.REPORTS, 'subscriptions', type, from, toDate],
        staleTime: 60_000,
        queryFn: () => fetchSubscriptionsReport(type, from, toDate),
    });
};