import {useQuery} from '@tanstack/react-query';
import httpClient from "../httpClient";
import {API_ENDPOINTS} from "../endpoints";
import {UserPermissions} from "../../models/UserPermissions";


export const fetchUserPermissions = async () => {
    const {data} = await httpClient.get(`${API_ENDPOINTS.USERS}/permissions`);
    return data.data as UserPermissions;
};
export const useGetUserPermissions = () => {
    return useQuery<UserPermissions, Error>({
        queryKey: [API_ENDPOINTS.USERS, 'permissions'],
        queryFn: fetchUserPermissions,
    });
};