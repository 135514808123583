import {useQuery} from '@tanstack/react-query';
import httpClient from "../httpClient";
import {API_ENDPOINTS} from "../endpoints";
import {CashierBalance} from "../../models/cashierBalance";


const getMyBalance = async () => {
    const {data} = await httpClient.get(`${API_ENDPOINTS.CASHIER}/my-balance`);
    return data.data as CashierBalance;
};
export const useFetchCashierMyBalance = () => {
    return useQuery<CashierBalance, Error>({
        queryKey: [API_ENDPOINTS.CASHIER, 'my-balance'],
        queryFn: getMyBalance,
    });
};