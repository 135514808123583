import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ManagedUIContext} from "./contexts/ui.context";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SnackbarProvider} from 'notistack';
import "moment/locale/he"
import moment from "moment";
import {createTheme} from '@mui/material/styles';
import {CssVarsProvider as JoyCssVarsProvider} from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {ConfirmProvider} from 'mui-joy-confirm';


moment.locale("he")

const theme = createTheme({
    direction: 'rtl',
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60_000,
            retry: false
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>

  <JoyCssVarsProvider>
              <CssBaseline enableColorScheme/>
      <QueryClientProvider client={queryClient}>
          <ManagedUIContext>
              <SnackbarProvider anchorOrigin={{vertical: "top", horizontal: "center"}} maxSnack={3}>
                  <ConfirmProvider>
                      <App/>
                  </ConfirmProvider>
              </SnackbarProvider>
          </ManagedUIContext>
      </QueryClientProvider>
          </JoyCssVarsProvider>
  </React.StrictMode>
);
