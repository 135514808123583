import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import {Link as RouterLink, useLocation} from 'react-router-dom';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import Sidebar from './Sidebar';
import Header from './Header';
import {getRouteByPath} from "./routes";
import {ChevronLeftRounded} from "@mui/icons-material";

const BreakCrumbsContainer = () => {
    const {pathname} = useLocation();
    const activeRoute = getRouteByPath(pathname)

    return (
        <Box sx={{position: 'sticky', display: 'flex', alignItems: 'center'}}>
            <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronLeftRounded/>}
                sx={{pl: 0}}
            >
                <Link
                    underline="none"
                    color="neutral"
                    component={RouterLink}
                    to="/"
                    aria-label="Home"
                >
                    <HomeRoundedIcon/>
                </Link>
                <Link
                    underline="hover" color="neutral" component={RouterLink} to={null}
                    sx={{fontSize: 12, fontWeight: 500}}>
                    {activeRoute?.label}
                </Link>
            </Breadcrumbs>
        </Box>
    )
}

export default function Layout({children}: React.PropsWithChildren) {
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Sidebar/>
                <Header/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        pt: {xs: 'calc(12px + var(--Header-height))', md: 3},
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                        overflow: 'auto',
                    }}>
                    <BreakCrumbsContainer/>
                    {children}
                </Box>
            </Box>
        </CssVarsProvider>
    );
}